import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Import AG Grid styles
import "ag-grid-community/styles/ag-theme-alpine.css"; // Import a theme
import EditIcon from "@mui/icons-material/Edit";
import useSetRenewal from "./hooks/useSetRenewal"; // Import the hook
import "./RenewalGrid.css"; // Import custom styles
import useButtonState from "./hooks/useButtonState";

const editableCellRenderer = (params) => {
  let displayValue = params.value;
  if (params.colDef.field === "price") {
    displayValue = `$${Number(params.value).toFixed(2)}`;
  }
  return (
    <div>
      {displayValue} <EditIcon style={{ fontSize: 16, color: "gray" }} />
    </div>
  );
};
const editableCellStyle = {
  display: "flex",
  alignItems: "center", // Ensures vertical centering
  justifyContent: "center", // Ensures horizontal centering
  backgroundColor: "#eff4f9",
  whiteSpace: "normal",
  lineHeight: "1.5",
};

const notEditableCellStyle = {
  display: "flex",
  alignItems: "center", // Ensures vertical centering
  justifyContent: "center", // Ensures horizontal centering
  whiteSpace: "normal",
  lineHeight: "1.5",
  wrapText: true,
  autoHeight: true,
};
const RenewalGrid = ({ rowData, onButtonClick }) => {
  const { renewalUpdateLoading, sendRowDataToApi } = useSetRenewal(); // Get the API call function from the hook
  const { disabledButtons, toggleButtonState } = useButtonState(rowData);

  // Define your columns as before

  const handleButtonClick = async (data) => {
    toggleButtonState(data.id, true); // Disable the button for this row
    console.log("Sending data to API", data);
    try {
      const response = await sendRowDataToApi(data);
      console.log("Data processed", response);
    } catch (error) {
      console.error("Failed to process data", error);
    } finally {
      toggleButtonState(data.id, false); // Re-enable the button for this row
    }
  };

  const onCellValueChanged = (event) => {
    if (event.column.colId === "billingCycle" && event.newValue === "Annual") {
      event.node.setDataValue("termDuration", "P1Y");
    } else if (
      event.column.colId === "termDuration" &&
      event.newValue === "P1M"
    ) {
      event.node.setDataValue("billingCycle", "Monthly");
    }
  };
  console.log("row data intial", rowData);
  const columnDefs = [
    {
      headerName: "Client",
      field: "organizationName",
      width: 150, // Default width for the column
      resizable: true,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Name",
      field: "offerName",
      width: 150, // Default width for the column
      resizable: true,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 100,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Commitment Term",
      field: "term",
      width: 100,
      resizable: true,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Billing Cycle",
      field: "billingCycle",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Monthly", "Annual"],
      },
      width: 120,
      resizable: true,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Unit Cost",
      field: "cost",
      width: 120,
      resizable: true,
      cellStyle: {
        notEditableCellStyle,
      },
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Unit Price",
      field: "price",

      editable: true,
      cellEditor: "agTextCellEditor",
      width: 120,
      resizable: true,
      cellRenderer: editableCellRenderer,
      cellStyle: editableCellStyle,
      headerClass: "ag-header-cell-label",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <Button
          onClick={() => handleButtonClick(params.data)}
          variant="contained"
          color="primary"
          disabled={disabledButtons[params.data.id]}
        >
          Set Price
        </Button>
      ),
      headerClass: "ag-header-cell-label",
      width: 200,
      resizable: true,
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: "auto", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        onCellValueChanged={onCellValueChanged}
        singleClickEdit={true} // Enables single click to edit
        stopEditingWhenCellsLoseFocus={true} // Stops editing when clicking outside the cell
      />
    </div>
  );
};

export default RenewalGrid;
