import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";

const useApiWithUrlParams = (apiEndpoint, upSideDownID) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Define the fetchData function with useCallback and debounce
  const fetchData = useCallback(
    debounce(async () => {
      if (!upSideDownID) {
        setData(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const response = await axios.post(
          apiEndpoint,
          { upSideDownID: upSideDownID },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError(
          err.response ? err.response.data : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    }, 300),
    [apiEndpoint, upSideDownID]
  ); // Debounce time is 300 ms

  useEffect(() => {
    fetchData();
    // Cleanup function to cancel the debounce on component unmount or input change
    return () => fetchData.cancel();
  }, [fetchData]); // Dependency on fetchData itself

  return {
    renewalData: data,
    renewalLoading: loading,
    renewalError: error,
  };
};

export default useApiWithUrlParams;
