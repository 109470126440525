import React from "react";

const Header = () => {
  return (
    <>
      <header class="header" role="banner">
        <a class="screen-reader-text skip-link" href="#main">
          Skip to content
        </a>
        <div class="header--container top--bar">
          <div class="grid-container">
            <div class="extra-links">
              <div class="inner-extra">
                <a href="/new-account-request/">Open an Account</a> |
                <a href="/tech-support-classic/">Tech Support</a> |{" "}
                <a href="#">1.855.ITsavvy</a> |
                <a href="/cdn-cgi/l/email-protection#0d64636b624d44597e6c7b7b74236e6260">
                  {/* <span class="__cf_email__"
                                        data-cfemail="fb92959d94bbb2af889a8d8d82d5989496">[email&nbsp;protected]</span> */}
                </a>
              </div>
            </div>

            <div class="row">
              <div class="grid-item main--logo">
                <a href="https://www.itsavvy.com">
                  <img
                    alt="logo"
                    src="https://www.itsavvy.com/wp-content/uploads/2021/01/itsavvy-logo.svg"
                  />
                </a>
              </div>

              <div class="grid-item product--search-wrap">
                <form class="product--search" method="get">
                  &nbsp;
                  <div class="form--submit">
                    <input name="submit" type="submit" value="GO" />
                  </div>
                </form>
              </div>

              <div class="ecomm--links desk">
                <div class="ecom-links-container"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="main--menu"
          style={{
            backgroundImage:
              "url('https://www.itsavvy.com/wp-content/uploads/2020/08/orange-circuit.png')",
          }}
        >
          <div class="header--container">
            <div class="mobile--menu">
              <ul>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
              </ul>
            </div>

            <nav class="mobile-menu" role="navigation">
              <div class="menu-mobile-menu-container">
                <ul class="menu" id="menu-mobile-menu">
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7551"
                    id="menu-item-7551"
                  >
                    <a href="https://www.itsavvy.com/shop/">Shop</a>

                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14344"
                        id="menu-item-14344"
                      >
                        <a href="https://savvyportal.itsavvy.com/Product/Search">
                          Products
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14345"
                        id="menu-item-14345"
                      >
                        <a href="/partners/">Brands</a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7552"
                    id="menu-item-7552"
                  >
                    <a href="https://www.itsavvy.com/solutions/">Solutions</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14346"
                        id="menu-item-14346"
                      >
                        <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/">
                          Supply Chain Optimization
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14352"
                        id="menu-item-14352"
                      >
                        <a href="https://www.itsavvy.com/solutions/cyber-security/">
                          Cybersecurity
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14353"
                        id="menu-item-14353"
                      >
                        <a href="https://www.itsavvy.com/solutions/network-wireless-infrastructure/">
                          Network &amp; Wireless Infrastructure
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14354"
                        id="menu-item-14354"
                      >
                        <a href="https://www.itsavvy.com/solutions/data-center-transformation/">
                          Data Center Transformation
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14355"
                        id="menu-item-14355"
                      >
                        <a href="https://www.itsavvy.com/solutions/workforce-enablement/">
                          Workforce Enablement
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14356"
                        id="menu-item-14356"
                      >
                        <a href="https://www.itsavvy.com/solutions/lifecycle-management/">
                          Lifecycle Management
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-15385"
                        id="menu-item-15385"
                      >
                        <a href="/solutions/chromebooks/">
                          Device As A Service
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7553"
                    id="menu-item-7553"
                  >
                    <a href="https://www.itsavvy.com/services/">Services</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14357"
                        id="menu-item-14357"
                      >
                        <a href="https://www.itsavvy.com/services/managed-services/">
                          Managed Services
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14358"
                        id="menu-item-14358"
                      >
                        <a href="https://www.itsavvy.com/services/managed-security-services/">
                          Managed Security Services
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14360"
                        id="menu-item-14360"
                      >
                        <a href="https://www.itsavvy.com/services/professional-services/">
                          Professional Services
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14361"
                        id="menu-item-14361"
                      >
                        <a href="https://www.itsavvy.com/services/field-services/">
                          Field Services
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14362"
                        id="menu-item-14362"
                      >
                        <a href="https://www.itsavvy.com/services/configuration-lab/">
                          Configuration Lab
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7554"
                    id="menu-item-7554"
                  >
                    <a href="https://www.itsavvy.com/cloud/">Cloud</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14363"
                        id="menu-item-14363"
                      >
                        <a href="https://www.itsavvy.com/cloud/microsoft-365/">
                          Microsoft 365
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14364"
                        id="menu-item-14364"
                      >
                        <a href="https://www.itsavvy.com/cloud/public/">
                          Cloud
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14365"
                        id="menu-item-14365"
                      >
                        <a href="https://www.itsavvy.com/cloud/hybrid/">
                          Hybrid
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14366"
                        id="menu-item-14366"
                      >
                        <a href="https://www.itsavvy.com/cloud/private-cloud/">
                          Private/Colocation
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14725"
                        id="menu-item-14725"
                      >
                        <a href="https://www.itsavvy.com/cloud/itsavvy-cloud-in-a-box/">
                          Cloud in a Box
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-7555"
                    id="menu-item-7555"
                  >
                    <a href="https://www.itsavvy.com/partners/">Partners</a>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7556"
                    id="menu-item-7556"
                  >
                    <a href="https://www.itsavvy.com/industries/">Industries</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14367"
                        id="menu-item-14367"
                      >
                        <a href="https://www.itsavvy.com/industries/federal-government/">
                          Federal Government
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14368"
                        id="menu-item-14368"
                      >
                        <a href="https://www.itsavvy.com/industries/state-and-local-government/">
                          State/Local Government
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14369"
                        id="menu-item-14369"
                      >
                        <a href="https://www.itsavvy.com/industries/education/">
                          Education
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14370"
                        id="menu-item-14370"
                      >
                        <a href="https://www.itsavvy.com/industries/not-for-profits/">
                          NFP
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14371"
                        id="menu-item-14371"
                      >
                        <a href="https://www.itsavvy.com/industries/smb/">
                          SMB
                        </a>
                      </li>
                      <li
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-14372"
                        id="menu-item-14372"
                      >
                        <a href="https://www.itsavvy.com/industries/enterprise/">
                          Enterprise
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-7557"
                    id="menu-item-7557"
                  >
                    <a href="https://www.itsavvy.com/contact-us/">Contact Us</a>
                  </li>
                </ul>
              </div>
            </nav>

            <nav class="desktop-menu" role="navigation">
              <div class="menu-main-menu-container">
                <ul class="menu" id="menu-main-menu">
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-32"
                    id="menu-item-32"
                  >
                    <a href="https://www.itsavvy.com/shop/">Shop</a>

                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7394"
                        id="menu-item-7394"
                      >
                        <div class="vc_row wpb_row vc_row-fluid megamenu-tabs">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div
                                  class="vc_tta-container"
                                  data-vc-action="collapse"
                                >
                                  <div class="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-left">
                                    <div class="vc_tta-tabs-container">
                                      <ul class="vc_tta-tabs-list">
                                        <li
                                          class="vc_tta-tab vc_active"
                                          data-vc-tab=""
                                        >
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199194409-76cad718-b4be"
                                          >
                                            <span class="vc_tta-title-text">
                                              Search by Products
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199203231-46108447-17c3"
                                          >
                                            <span class="vc_tta-title-text">
                                              Search by Brands
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="vc_tta-panels-container">
                                      <div class="vc_tta-panels">
                                        <div
                                          class="vc_tta-panel vc_active"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199194409-76cad718-b4be"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199194409-76cad718-b4be"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Search by Products
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-6">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>Hardware</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100101">
                                                            Desktops &amp;
                                                            Workstations
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100102">
                                                            Notebooks &amp;
                                                            Accessories
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100201">
                                                            Monitors
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/10050801">
                                                            Firewall / VPN
                                                            Appliances
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100104">
                                                            Servers
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/10040702">
                                                            SAN
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/30010206">
                                                            Video Conferencing
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100602">
                                                            Cables
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100502">
                                                            Switches
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/100507">
                                                            Wireless
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-6">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>Software</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/200101">
                                                            Endpoint Security
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20011108">
                                                            Backup &amp;
                                                            Disaster Recovery
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20010408">
                                                            Network &amp;
                                                            Enterprise
                                                            Management
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/200201">
                                                            Microsoft Windows
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20011110">
                                                            Virtualization
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20010107">
                                                            Web Filtering
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20011112">
                                                            Mobile Device
                                                            Management
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/200107">
                                                            Audio &amp; Video
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/200103">
                                                            Business &amp;
                                                            Productivity
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://savvyportal.itsavvy.com/Product/Search/CategoryCode/20010104">
                                                            Authentication
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="vc_btn3-container vc_btn3-left">
                                                      <a
                                                        class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey"
                                                        href="https://savvyportal.itsavvy.com/Product/Search"
                                                        target="_blank"
                                                        title="Search All Products"
                                                      >
                                                        Search All Products
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199203231-46108447-17c3"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199203231-46108447-17c3"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Search by Brands
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>Brands</h3>

                                                        <hr />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/8x8/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt="ITsavvy | 8x8 Cloud Communications"
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="122"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/8x8-color-logo.png"
                                                            title="8x8-color-logo"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_left">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=apc%20-%20schneider%20electric"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            fetchpriority="high"
                                                            height="855"
                                                            sizes="(max-width: 1357px) 100vw, 1357px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/pngegg.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/pngegg.png 1357w, https://www.itsavvy.com/wp-content/uploads/2021/02/pngegg-300x189.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/pngegg-1024x645.png 1024w, https://www.itsavvy.com/wp-content/uploads/2021/02/pngegg-768x484.png 768w"
                                                            title="pngegg"
                                                            width="1357"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/apple/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="314"
                                                            sizes="(max-width: 793px) 100vw, 793px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/Apple-Auth-Black.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/01/Apple-Auth-Black.png 793w, https://www.itsavvy.com/wp-content/uploads/2021/01/Apple-Auth-Black-300x119.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/01/Apple-Auth-Black-768x304.png 768w"
                                                            title="Apple-Auth-Black"
                                                            width="793"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/aruba-an-hpe-company/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="122"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/aruba-logo-color.png"
                                                            title="aruba-logo-color"
                                                            width="250"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/cisco/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt="Cisco Premier Partner logo"
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="300"
                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/cisco-premier-partner-2021.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/01/cisco-premier-partner-2021.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/01/cisco-premier-partner-2021-150x150.png 150w"
                                                            title="cisco-premier-partner-2021"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/cisco-meraki/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="58"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/cisco-meraki-logo.png"
                                                            title="cisco-meraki-logo"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/dell-client/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="299"
                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/Dell_Logo-color-logo.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/Dell_Logo-color-logo.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/Dell_Logo-color-logo-150x150.png 150w"
                                                            title="Dell_Logo-color-logo"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/dell-technologies/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="256"
                                                            sizes="(max-width: 879px) 100vw, 879px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/Dell-Titanium.jpg"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/01/Dell-Titanium.jpg 879w, https://www.itsavvy.com/wp-content/uploads/2021/01/Dell-Titanium-300x87.jpg 300w, https://www.itsavvy.com/wp-content/uploads/2021/01/Dell-Titanium-768x224.jpg 768w"
                                                            title="Dell-Titanium"
                                                            width="879"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/hp-inc/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="257"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/hp-inc-logo-2.png"
                                                            title="hp-inc-logo-2"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/hewlett-packard-enterprise/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="132"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/hpe-logo-color.png"
                                                            title="hpe-logo-color"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/juniper/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="125"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/juniper-color-logo.png"
                                                            title="juniper-color-logo"
                                                            width="250"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/lenovo/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt="Lenovo"
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="300"
                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/06/lenovo-pcg-partner-logo.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/06/lenovo-pcg-partner-logo.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/06/lenovo-pcg-partner-logo-150x150.png 150w"
                                                            title=""
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/microsoft-csp/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="157"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/microsoft-csp-logo-color.png"
                                                            title=""
                                                            width="260"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/microsoft-surface/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="157"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/microsoft-surface-color-logo.png"
                                                            title=""
                                                            width="260"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/palo-alto-networks/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="46"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/01/palo-alto-color-logo.png"
                                                            title="palo-alto-color-logo"
                                                            width="250"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/ring-central/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="53"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/RingCentral-logo-color.png"
                                                            title="RingCentral-logo-color"
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_left  vc_custom_1615524142912">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=veeam%20software"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt=""
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="189"
                                                            sizes="(max-width: 1048px) 100vw, 1048px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280.png 1048w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-300x54.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-1024x185.png 1024w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-768x139.png 768w"
                                                            title="veeam_logo_topaz-500.png.web.1280.1280"
                                                            width="1048"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <figure class="wpb_wrapper vc_figure">
                                                        <a
                                                          class="vc_single_image-wrapper   vc_box_border_grey"
                                                          href="/partners/vmware/"
                                                          target="_self"
                                                        >
                                                          <img
                                                            alt="VMWare"
                                                            class="vc_single_image-img attachment-full"
                                                            decoding="async"
                                                            height="300"
                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/vmware-generic-cert-logo-2021.png"
                                                            srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/vmware-generic-cert-logo-2021.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/vmware-generic-cert-logo-2021-150x150.png 150w"
                                                            title=""
                                                            width="300"
                                                          />
                                                        </a>
                                                      </figure>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="vc_btn3-container vc_btn3-left">
                                                      <a
                                                        class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey"
                                                        href="https://www.itsavvy.com/partners/"
                                                        title="See All Brands"
                                                      >
                                                        See All Brands
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-31"
                    id="menu-item-31"
                  >
                    <a href="https://www.itsavvy.com/solutions/">Solutions</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7334"
                        id="menu-item-7334"
                      >
                        <div class="vc_row wpb_row vc_row-fluid megamenu-tabs">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div
                                  class="vc_tta-container"
                                  data-vc-action="collapse"
                                >
                                  <div class="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-left">
                                    <div class="vc_tta-tabs-container">
                                      <ul class="vc_tta-tabs-list">
                                        <li
                                          class="vc_tta-tab vc_active"
                                          data-vc-tab=""
                                        >
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612198778214-bd250c1c-82d2"
                                          >
                                            <span class="vc_tta-title-text">
                                              Supply Chain Optimization
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199131181-f0525474-592d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Cybersecurity
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199158790-1edae25f-7e2d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Network &amp; Wireless
                                              Infrastructure
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1613079997580-eb11da2e-2996"
                                          >
                                            <span class="vc_tta-title-text">
                                              Data Center Transformation
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199194409-76cad718-b4be"
                                          >
                                            <span class="vc_tta-title-text">
                                              Workforce Enablement
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199203231-46108447-17c3"
                                          >
                                            <span class="vc_tta-title-text">
                                              Lifecycle Management
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1657655816210-2cf008ff-f109"
                                          >
                                            <span class="vc_tta-title-text">
                                              Device as a Service
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="vc_tta-panels-container">
                                      <div class="vc_tta-panels">
                                        <div
                                          class="vc_tta-panel vc_active hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612198778214-bd250c1c-82d2"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612198778214-bd250c1c-82d2"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Supply Chain Optimization
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/">
                                                            Supply Chain
                                                            Optimization
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/eprocurement/">
                                                            E-procurement
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/punchout-tool/">
                                                            Punchout Tool
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/custom-catalog/">
                                                            Custom Catalog
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/bundling/">
                                                            Bundling
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/just-in-time-inventory/">
                                                            Just-In-Time
                                                            Inventory
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/supply-chain-optimization/reporting/">
                                                            Reporting
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Centralize and consolidate vendors."}'
                                                          >
                                                            Centralize and
                                                            consolidate vendors.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Improve purchasing
                                                          efficiency, manage
                                                          vendor relationships,
                                                          and offer greater
                                                          value with
                                                          ITsavvy&#39;s
                                                          eprocurement
                                                          solutions.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Simplify procurement. "}'
                                                          >
                                                            Simplify
                                                            procurement.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Purchase hardware and software in an integrated platform for a more efficient acquisition experience. "}'
                                                          >
                                                            Purchase hardware
                                                            and software in an
                                                            integrated platform
                                                            for a more efficient
                                                            acquisition
                                                            experience.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Limit selection,
                                                          simplify procurement.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Standardize your IT
                                                          environment with
                                                          cost-optimized custom
                                                          catalog solutions.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Remove the guesswork.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Bundling standardizes
                                                          product packages to
                                                          speed up the ordering
                                                          process.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Prevent unavailable
                                                          inventory.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          JIT helps you access
                                                          your supply chain
                                                          without the delays.
                                                          Prevent supply chain
                                                          disruptions, stay
                                                          flexible, and enjoy a
                                                          holistic approach.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Maximize your IT
                                                          investments.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Supply chain reporting
                                                          built for modern
                                                          business. ITsavvy
                                                          reporting turns your
                                                          raw data into wisdom,
                                                          predicting future
                                                          performance and
                                                          streamlining your
                                                          business.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199131181-f0525474-592d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199131181-f0525474-592d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Cybersecurity
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/cyber-security/">
                                                            Cybersecurity
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/solutions/cyber-security/threat-detection-and-incident-response/">
                                                            Threat Detection
                                                            &amp; Incident
                                                            Response
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/cyber-security/identity-management/">
                                                            Identity Management
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/cyber-security/cloud-security/">
                                                            Cloud &amp; SASE
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/cyber-security/data-management-intelligence/">
                                                            Data Management
                                                            &amp; Intelligence
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/cyber-security/email-anti-phishing/">
                                                            Email &amp;
                                                            Anti-Phishing
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/cyber-security/security-awareness-training/">
                                                            Security Awareness
                                                            Training
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Peace of
                                                          mind&mdash;24/7/365.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Classify and spot
                                                          attacks quickly.
                                                          ITsavvy solutions can
                                                          even rewind the clock
                                                          and disrupt threats
                                                          quickly.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Security no matter the
                                                          environment.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Connect your team
                                                          without the security
                                                          issues. Identity
                                                          management efficiently
                                                          verifies users in your
                                                          systems.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Enjoy the freedom of
                                                          the
                                                          cloud&mdash;securely.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy sets you up
                                                          for cloud success with
                                                          best-in-class
                                                          technology and
                                                          cybersecurity
                                                          protocols.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Do you have control
                                                          over your data?
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Data management gets
                                                          messy. Rely on our
                                                          solutions to mitigate
                                                          threats and secure
                                                          your valuable
                                                          information.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Don&#39;t fall for a
                                                          scam.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy protects your
                                                          confidential
                                                          information from
                                                          nefarious phishing
                                                          schemes with threat
                                                          database monitoring,
                                                          data encryption, and
                                                          data leak prevention.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          90% of cyber attacks
                                                          come from email
                                                          phishing.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Your systems are only
                                                          as secure as your
                                                          employees&rsquo;
                                                          security awareness.
                                                          Trust ITsavvy to arm
                                                          your team with
                                                          security best
                                                          practices.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/09/ITsavvy-Cybersecurity-Solutions.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/09/cybersecurity-thumb.jpg"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199158790-1edae25f-7e2d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199158790-1edae25f-7e2d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Network &amp; Wireless
                                                  Infrastructure
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/network-wireless-infrastructure/">
                                                            Network &amp;
                                                            Wireless
                                                            Infrastructure
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <a href="https://www.itsavvy.com/solutions/network-wireless-infrastructure/wireless/">
                                                            Wireless
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="/solutions/network-wireless-infrastructure/network-security/">
                                                            Security
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="https://www.itsavvy.com/solutions/network-wireless-infrastructure/network-visibility/">
                                                            Visibility
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="/solutions/network-wireless-infrastructure/connectivity/">
                                                            Connectivity
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="https://www.itsavvy.com/solutions/network-wireless-infrastructure/sd-wan/">
                                                            SD-WAN
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="https://www.itsavvy.com/solutions/network-wireless-infrastructure/lan/">
                                                            LAN
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Off-the-shelf wireless doesn’t cut it."}'
                                                          >
                                                            Off-the-shelf
                                                            wireless
                                                            doesn&rsquo;t cut
                                                            it.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Every organization has different connectivity needs. That’s why off-the-shelf solutions just don’t work. ITsavvy’s wireless solutions fix coverage issues andkeep your workforce connected."}'
                                                          >
                                                            Every organization
                                                            has different
                                                            connectivity needs.
                                                            That&rsquo;s why
                                                            off-the-shelf
                                                            solutions just
                                                            don&rsquo;t work.
                                                            ITsavvy&rsquo;s
                                                            wireless solutions
                                                            fix coverage issues
                                                            and keep your
                                                            workforce connected.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"A cyber attack can happen at any time."}'
                                                          >
                                                            A cyber attack can
                                                            happen at any time.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Secure your hardware, virtual machines, printers, and more against an attack. ITsavvy&apos;s proactive network security solutions give you more peace of mind."}'
                                                          >
                                                            Secure your
                                                            hardware, virtual
                                                            machines, printers,
                                                            and more against an
                                                            attack.
                                                            ITsavvy&#39;s
                                                            proactive network
                                                            security solutions
                                                            give you more peace
                                                            of mind.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Don’t bury your head in the sand."}'
                                                          >
                                                            Don&rsquo;t bury
                                                            your head in the
                                                            sand.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"See who’s doing what on your network. ITsavvy&apos;s granular insights improve your network visibility and performance. "}'
                                                          >
                                                            See who&rsquo;s
                                                            doing what on your
                                                            network.
                                                            ITsavvy&#39;s
                                                            granular insights
                                                            improve your network
                                                            visibility and
                                                            performance.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Keep your team going, no matter what."}'
                                                          >
                                                            Keep your team
                                                            going, no matter
                                                            what.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Connectivity solutions keep your business moving. Reliably connect your IoT devices and locations with ITsavvy."}'
                                                          >
                                                            Connectivity
                                                            solutions keep your
                                                            business moving.
                                                            Reliably connect
                                                            your IoT devices and
                                                            locations with
                                                            ITsavvy.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Keep your head in the clouds with SD-WAN.\r\n"}'
                                                          >
                                                            Keep your head in
                                                            the clouds with
                                                            SD-WAN.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Don’t worry about cloud security. SD-WAN architecture centralizes your network for more intelligent connectivity."}'
                                                          >
                                                            Don&rsquo;t worry
                                                            about cloud
                                                            security. SD-WAN
                                                            architecture
                                                            centralizes your
                                                            network for more
                                                            intelligent
                                                            connectivity.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Minimize the headaches of wired connections."}'
                                                          >
                                                            Minimize the
                                                            headaches of wired
                                                            connections.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Set up affordable, reliable high-speed internet at your location. ITsavv’ys LAN solution connects devices in a limited area at high speeds. "}'
                                                          >
                                                            Set up affordable,
                                                            reliable high-speed
                                                            internet at your
                                                            location.
                                                            ITsavvy&rsquo;s LAN
                                                            solution connects
                                                            devices in a limited
                                                            area at high speeds.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1613079997580-eb11da2e-2996"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1613079997580-eb11da2e-2996"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Data Center Transformation
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/data-center-transformation/">
                                                            Data Center
                                                            Transformation
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/data-center-transformation/storage/">
                                                            Storage
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/data-center-transformation/servers/">
                                                            Servers
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/data-center-transformation/backup-and-disaster-recovery/">
                                                            Backup &amp; DR
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/data-center-transformation/data-protection/">
                                                            Data Protection
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/data-center-transformation/hybrid-cloud/">
                                                            Hybrid Cloud
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/data-center-transformation/hyper-converged-infrastructure/">
                                                            Hyperconverged
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/data-center-transformation/data-center-services/">
                                                            Data Center Services
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Break through your
                                                          data storage
                                                          challenges.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Remove the data
                                                          barriers. Get your
                                                          must-have storage
                                                          features while keeping
                                                          your data accessible.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Process large amounts
                                                          of data without
                                                          breaking a sweat.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Your network processes
                                                          a lot of data. Our
                                                          best of breed partners
                                                          help you do it without
                                                          the downtime.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          One event is all it
                                                          takes to lose your
                                                          data.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          The best time to
                                                          prepare for an event
                                                          is before a
                                                          catastrophe happens.
                                                          ITsavvy&rsquo;s backup
                                                          and disaster recovery
                                                          solutions ensure
                                                          business continuity to
                                                          give you peace of
                                                          mind.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Stop chaos before it disrupts your organization. \r"}'
                                                          >
                                                            Stop chaos before it
                                                            disrupts your
                                                            organization.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Data protection
                                                          proactively stops data
                                                          losses with continuous
                                                          protection.
                                                          ITsavvy&rsquo;s data
                                                          protection solutions
                                                          transform chaos into
                                                          peace of mind.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Enjoy the best of both
                                                          worlds.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s hybrid
                                                          cloud solutions allow
                                                          you to architect a
                                                          flexible
                                                          infrastructure with
                                                          public cloud, private
                                                          cloud, on-premise, or
                                                          colocation options.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Optimize your
                                                          on-premise data
                                                          center.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Hyper-converged
                                                          infrastructure helps
                                                          you balance security
                                                          and convenience.
                                                          ITsavvy&rsquo;s
                                                          partner-certified
                                                          experts help you
                                                          choose a solution that
                                                          combines your storage,
                                                          servers, and networks
                                                          in one central
                                                          platform.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Expert help when you
                                                          need it the most.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Get the expert help
                                                          you need with
                                                          ITsavvy&rsquo;s data
                                                          center services. Our
                                                          on-demand help
                                                          augments your in-house
                                                          team.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199194409-76cad718-b4be"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199194409-76cad718-b4be"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Workforce Enablement
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/workforce-enablement/">
                                                            Workforce Enablement
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/solutions/workforce-enablement/microsoft-365/">
                                                            Microsoft 365
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/workforce-enablement/mobile-device-management/">
                                                            Mobile Device
                                                            Management
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/workforce-enablement/endpoint-solutions/">
                                                            Endpoint Solutions
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/workforce-enablement/ucaas/">
                                                            UCaaS
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/solutions/workforce-enablement/remote-collaboration/">
                                                            Remote Collaboration
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Get the most out of
                                                          your day.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s cloud
                                                          and Microsoft 365
                                                          experts can guide your
                                                          implementations to
                                                          maximize business
                                                          agility and cloud ROI.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"BYOD made easy. "}'
                                                          >
                                                            BYOD made easy.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Regain control over
                                                          the devices on your
                                                          network. ITsavvy
                                                          connects devices
                                                          seamlessly and
                                                          securely, safely
                                                          enabling
                                                          work-from-anywhere
                                                          capabilities.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Empower your
                                                          workforce.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Give non-programmers
                                                          the freedom to
                                                          securely collaborate
                                                          at any time, any
                                                          place. ITsavvy
                                                          simplifies and
                                                          modernizes user
                                                          environments to vastly
                                                          improve user
                                                          experience and work
                                                          efficiency.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          One provider, endless
                                                          possibilities.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          The UCaaS cloud model
                                                          saves time and costs
                                                          to give your team
                                                          peace of mind.
                                                          Consolidate your
                                                          telephony,
                                                          conferencing, and
                                                          communications needs
                                                          with one provider.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Collaboration for a
                                                          24/7 world.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Streamline your
                                                          organization and
                                                          connect employees
                                                          wherever they are.
                                                          ITsavvy architects the
                                                          right technology for
                                                          your organization to
                                                          enable remote
                                                          collaboration.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199203231-46108447-17c3"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199203231-46108447-17c3"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Lifecycle Management
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/lifecycle-management/">
                                                            Lifecycle Management
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/solutions/lifecycle-management/our-focus/">
                                                            Our Focus
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/lifecycle-management/asset-management-dashboard/">
                                                            Asset Management
                                                            Dashboard
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/lifecycle-management/renewal-dashboard/">
                                                            Renewal Dashboard
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/lifecycle-management/our-team/">
                                                            Our Team
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Finally control your
                                                          lifecycle.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy offers
                                                          lifecycle management
                                                          as a value-add for all
                                                          of our clients. Learn
                                                          more about our
                                                          approach and what sets
                                                          our IT lifecycle
                                                          management solution
                                                          apart.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Lifecycle-Management-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/lifecyle-thumb-rev2.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Remove disruptions. "}'
                                                          >
                                                            Remove disruptions.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Achieve a greater return on your investment with ITsavvy. Our asset management dashboard tracks and optimizes asset lifecycles to run your business more efficiently. "}'
                                                          >
                                                            Achieve a greater
                                                            return on your
                                                            investment with
                                                            ITsavvy. Our asset
                                                            management dashboard
                                                            tracks and optimizes
                                                            asset lifecycles to
                                                            run your business
                                                            more efficiently.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Lifecycle-Management-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/lifecyle-thumb-rev2.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"No more lapsed licenses. "}'
                                                          >
                                                            No more lapsed
                                                            licenses.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Track and manage your expiration dates, warranty details, service levels, and more with ITsavvy&apos;s efficient renewal dashboard. "}'
                                                          >
                                                            Track and manage
                                                            your expiration
                                                            dates, warranty
                                                            details, service
                                                            levels, and more
                                                            with ITsavvy&#39;s
                                                            efficient renewal
                                                            dashboard.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Lifecycle-Management-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/lifecyle-thumb-rev2.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Human-centered lifecycle management. "}'
                                                          >
                                                            Human-centered
                                                            lifecycle
                                                            management.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Your IT infrastructure
                                                          needs comprehensive
                                                          guidance. Learn more
                                                          about our team&rsquo;s
                                                          unique approach to
                                                          lifecycle management
                                                          powered by
                                                          custom-built
                                                          technology.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Lifecycle-Management-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/lifecyle-thumb-rev2.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1657655816210-2cf008ff-f109"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1657655816210-2cf008ff-f109"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Device as a Service
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/solutions/chromebooks">
                                                            Chromebooks
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/solutions/chromebooks/k12-computing/">
                                                            K-12 Computing
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/chromebooks/chromecare-warranty/">
                                                            ChromeCare Warranty
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/chromebooks/white-glove-services/">
                                                            White Glove Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/solutions/chromebooks/device-buyback/">
                                                            Device Buyback
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Manage your Chrome
                                                          assets.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Our K-12 Computing
                                                          Solution provides an
                                                          end-to-end service
                                                          capability for
                                                          Chromebooks for K-12
                                                          schools.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="/wp-content/uploads/2022/01/ITsavvy-ChromeCare.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="/wp-content/uploads/2022/01/chrome-books-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-30"
                    id="menu-item-30"
                  >
                    <a href="https://www.itsavvy.com/services/">Services</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7365"
                        id="menu-item-7365"
                      >
                        <div class="vc_row wpb_row vc_row-fluid megamenu-tabs">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div
                                  class="vc_tta-container"
                                  data-vc-action="collapse"
                                >
                                  <div class="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-left">
                                    <div class="vc_tta-tabs-container">
                                      <ul class="vc_tta-tabs-list">
                                        <li
                                          class="vc_tta-tab vc_active"
                                          data-vc-tab=""
                                        >
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612198778214-bd250c1c-82d2"
                                          >
                                            <span class="vc_tta-title-text">
                                              Managed Services
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199131181-f0525474-592d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Managed Security Services
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199158790-1edae25f-7e2d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Professional Services
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199194409-76cad718-b4be"
                                          >
                                            <span class="vc_tta-title-text">
                                              Field Services
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199203231-46108447-17c3"
                                          >
                                            <span class="vc_tta-title-text">
                                              Configuration Lab
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="vc_tta-panels-container">
                                      <div class="vc_tta-panels">
                                        <div
                                          class="vc_tta-panel vc_active hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612198778214-bd250c1c-82d2"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612198778214-bd250c1c-82d2"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Managed Services
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/services/managed-services/">
                                                            Managed Services
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-services/managed-noc/">
                                                            Managed NOC
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-services/managed-help-desk/">
                                                            Managed Help Desk
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/managed-services/managed-microsoft-365/">
                                                            Managed Microsoft
                                                            365
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-services/managed-azure/">
                                                            Managed Azure
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Good-bye, infrastructure problems. "}'
                                                          >
                                                            Good-bye,
                                                            infrastructure
                                                            problems.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s
                                                          managed NOC service,
                                                          savvyGuard&reg;, puts
                                                          your infrastructure
                                                          under expert care.
                                                          ITsavvy&#39;s team of
                                                          certified engineers
                                                          monitor your network
                                                          24/7/365.
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy_Managed_Services_savvyGuard.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/02/SaavyGuard-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Give users what they need, right now."}'
                                                          >
                                                            Give users what they
                                                            need, right now.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Our managed help desk solution gets your users working again ASAP. ITsavvy&apos;s team of engineers keeps your end-users efficient with ongoing, expert support. \r\n"}'
                                                          >
                                                            Our managed help
                                                            desk solution gets
                                                            your users working
                                                            again ASAP.
                                                            ITsavvy&#39;s team
                                                            of engineers keeps
                                                            your end-users
                                                            efficient with
                                                            ongoing, expert
                                                            support.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy_Managed_Services_savvyGuard.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/02/SaavyGuard-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"200+ services in one place.\r\n"}'
                                                          >
                                                            M365 management that
                                                            doesn&#39;t quit.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s managed Azure service gives you the benefits of the cloud without the headaches.\r\n"}'
                                                          >
                                                            Let our
                                                            Microsoft-certified
                                                            experts handle your
                                                            Microsoft 365
                                                            migration,
                                                            maintenance,
                                                            licensing, support,
                                                            and more.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy_Managed_Services_savvyGuard.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/02/SaavyGuard-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"200+ services in one place.\r\n"}'
                                                          >
                                                            200+ services in one
                                                            place.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s managed Azure service gives you the benefits of the cloud without the headaches.\r\n"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            managed Azure
                                                            service gives you
                                                            the benefits of the
                                                            cloud without the
                                                            headaches.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy_Managed_Services_savvyGuard.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/02/SaavyGuard-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199131181-f0525474-592d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199131181-f0525474-592d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Managed Security Services
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/services/managed-security-services/">
                                                            Managed Security
                                                            Services
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/managed-detection-and-response/">
                                                            Managed Detection
                                                            &amp; Response
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/active-hunting/">
                                                            Active Hunting
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/advanced-analysis/">
                                                            Advanced Analysis
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/incident-response/">
                                                            Incident Response
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/cyber-posture-assessment/">
                                                            Cyber Posture
                                                            Assessment
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/managed-security-services/compromise-assessment/">
                                                            Compromise
                                                            Assessment
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Complacency puts you
                                                          in harm&rsquo;s way.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          One breach can easily
                                                          wreak havoc on your
                                                          organization.
                                                          ITsavvy&rsquo;s
                                                          managed SOC services
                                                          keep you safe.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Your security has gaps. "}'
                                                          >
                                                            Your security has
                                                            gaps.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Find vulnerabilities before your attackers. We not only hunt for malicious activity, but notify you of where your security infrastructure needs improvement. "}'
                                                          >
                                                            Find vulnerabilities
                                                            before your
                                                            attackers. We not
                                                            only hunt for
                                                            malicious activity,
                                                            but notify you of
                                                            where your security
                                                            infrastructure needs
                                                            improvement.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Ignorance isn&apos;t bliss. "}'
                                                          >
                                                            Ignorance isn&#39;t
                                                            bliss.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s advanced analysis service shows you what assets are most at risk and creates a security infrastructure based on your unique needs."}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            advanced analysis
                                                            service shows you
                                                            what assets are most
                                                            at risk and creates
                                                            a security
                                                            infrastructure based
                                                            on your unique
                                                            needs.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Address breaches in seconds. "}'
                                                          >
                                                            Address breaches in
                                                            seconds.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"How quickly can you respond to a breach? ITsavvy’s incident response service goes on the offensive to contain attacks quickly."}'
                                                          >
                                                            How quickly can you
                                                            respond to a breach?
                                                            ITsavvy&rsquo;s
                                                            incident response
                                                            service goes on the
                                                            offensive to contain
                                                            attacks quickly.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"How do you score? "}'
                                                          >
                                                            How do you score?
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s cyber posture assessment service is the first step to a secure infrastructure. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            cyber posture
                                                            assessment service
                                                            is the first step to
                                                            a secure
                                                            infrastructure.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":13057,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Learn from malicious agents in your environment."}'
                                                          >
                                                            Learn from malicious
                                                            agents in your
                                                            environment.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s compromise assessment identifies malicious activity in your environment. See how our assessment helps you overcome breaches. "}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            compromise
                                                            assessment
                                                            identifies malicious
                                                            activity in your
                                                            environment. See how
                                                            our assessment helps
                                                            you overcome
                                                            breaches.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199158790-1edae25f-7e2d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199158790-1edae25f-7e2d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Professional Services
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/services/professional-services/">
                                                            Professional
                                                            Services
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/services/professional-services/assessments/">
                                                            Assessments
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/professional-services/design-architecture/">
                                                            Design &amp;
                                                            Architecture
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/professional-services/migration/">
                                                            Migration
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/professional-services/integration/">
                                                            Integration
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Expert insight into
                                                          your environment
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s
                                                          assessment services
                                                          identify critical gaps
                                                          in your
                                                          infrastructure. See
                                                          how our experts can
                                                          help you transform the
                                                          way you do business.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Designed with work in
                                                          mind.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s
                                                          certified IT
                                                          professionals are here
                                                          to give you peace of
                                                          mind. Our design and
                                                          architecture framework
                                                          ensures your network,
                                                          storage, wireless,
                                                          cloud, and other
                                                          systems are scalable,
                                                          resilient, and secure.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Migrate without the
                                                          hassle.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s
                                                          certified cloud
                                                          experts are here to
                                                          give you peace of mind
                                                          as you move to the
                                                          cloud. Trust our team
                                                          to architect, build,
                                                          design, and implement
                                                          your move, whether
                                                          you&rsquo;re migrating
                                                          away from legacy
                                                          systems or moving to
                                                          Microsoft 365.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Integration done right
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          Keep your data center
                                                          operating smoothly.
                                                          ITsavvy&rsquo;s
                                                          implementation
                                                          engineers and field
                                                          technicians configure,
                                                          stage, rack, stack,
                                                          and deploy your data
                                                          center with years of
                                                          expertise.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199194409-76cad718-b4be"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199194409-76cad718-b4be"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Field Services
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/services/field-services/">
                                                            Field Services
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="/services/field-services/break-fix/">
                                                            Break/Fix
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/field-services/retail-it/">
                                                            Retail IT
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/field-services/audio-visual/">
                                                            Audio Visual
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/field-services/technology-refresh-and-rollout/">
                                                            Technology Refreshes
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/field-services/structured-cabling/">
                                                            Structured Cabling
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/services/field-services/staff-augmentation/">
                                                            Staff Augmentation
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Productivity is just a call away.\r\n"}'
                                                          >
                                                            Productivity is just
                                                            a call away.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Request reliable break/fix service from ITsavvy’s professional technicians. Get help repairing your devices in as little as 4 hours, 24/7/365.\r"}'
                                                          >
                                                            Request reliable
                                                            break/fix service
                                                            from ITsavvy&rsquo;s
                                                            professional
                                                            technicians. Get
                                                            help repairing your
                                                            devices in as little
                                                            as 4 hours,
                                                            24/7/365.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Roll out your retail locations flawlessly."}'
                                                          >
                                                            Roll out your retail
                                                            locations
                                                            flawlessly.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Boost your retail earnings with smart IT solutions tailored to each location. "}'
                                                          >
                                                            Boost your retail
                                                            earnings with smart
                                                            IT solutions
                                                            tailored to each
                                                            location.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"AV from A to Z."}'
                                                          >
                                                            AV from A to Z.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy procures, installs, and maintains audio visual equipment with our team of always-on, vendor-certified technicians. "}'
                                                          >
                                                            ITsavvy procures,
                                                            installs, and
                                                            maintains audio
                                                            visual equipment
                                                            with our team of
                                                            always-on,
                                                            vendor-certified
                                                            technicians.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Best in breed innovations. "}'
                                                          >
                                                            Best in breed
                                                            innovations.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s refresh and rollout technicians implement your infrastructure on budget and on time.\r\n"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            refresh and rollout
                                                            technicians
                                                            implement your
                                                            infrastructure on
                                                            budget and on time.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          From a tangled mess to
                                                          organized perfection.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s structured cabling service keeps you connected, productive, and profitable. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            structured cabling
                                                            service keeps you
                                                            connected,
                                                            productive, and
                                                            profitable.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Internal resources without the expense. "}'
                                                          >
                                                            Internal resources
                                                            without the expense.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s recognized experts augment your team to reduce overhead costs and meet deadlines, giving you peace of mind."}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            recognized experts
                                                            augment your team to
                                                            reduce overhead
                                                            costs and meet
                                                            deadlines, giving
                                                            you peace of mind.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-Field-Services-Brochure.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/field-services-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199203231-46108447-17c3"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199203231-46108447-17c3"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Configuration Lab
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/services/configuration-lab/">
                                                            Configuration Lab
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/configuration-lab/simple-ordering/">
                                                            Simple Ordering
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/configuration-lab/quality-control/">
                                                            Quality Control
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/configuration-lab/imaging/">
                                                            Imaging
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/configuration-lab/staging-and-configuration/">
                                                            Staging &amp;
                                                            Configuration
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/services/configuration-lab/custom-kitting/">
                                                            Custom Kitting
                                                          </a>
                                                        </p>

                                                        <p>
                                                          <a href="/configuration-lab/asset-management/">
                                                            Asset Management
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Order what you need. We take care of the rest. "}'
                                                          >
                                                            Order what you need.
                                                            We take care of the
                                                            rest.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy&apos;s configuration lab process means getting the products you need quickly, configured to your specs. "}'
                                                          >
                                                            ITsavvy&#39;s
                                                            configuration lab
                                                            process means
                                                            getting the products
                                                            you need quickly,
                                                            configured to your
                                                            specs.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Know your assets are ready from day one."}'
                                                          >
                                                            Know your assets are
                                                            ready from day one.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Our quality control (QC) process taps our technician’ and supervisors’ expertise to ensure your assets arrive correctly-configured and defect-free."}'
                                                          >
                                                            Our quality control
                                                            (QC) process taps
                                                            our
                                                            technician&rsquo;
                                                            and
                                                            supervisors&rsquo;
                                                            expertise to ensure
                                                            your assets arrive
                                                            correctly-configured
                                                            and defect-free.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Customize without compromise.\r\n"}'
                                                          >
                                                            Customize without
                                                            compromise.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Match your users to the right application set so they get exactly what they need to be productive with ITsavvy’s imaging services."}'
                                                          >
                                                            Match your users to
                                                            the right
                                                            application set so
                                                            they get exactly
                                                            what they need to be
                                                            productive with
                                                            ITsavvy&rsquo;s
                                                            imaging services.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Customize hardware as you see fit."}'
                                                          >
                                                            Customize hardware
                                                            as you see fit.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s configuration service customizes off-the-shelf hardware to your team’s needs on a per-device basis.\r\n"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            configuration
                                                            service customizes
                                                            off-the-shelf
                                                            hardware to your
                                                            team&rsquo;s needs
                                                            on a per-device
                                                            basis.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Setup is as easy as opening a box. \r"}'
                                                          >
                                                            Setup is as easy as
                                                            opening a box.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Rely on ITsavvy’s vendor-certified experts to design a custom kit built just for your team, creating an “office in a box” turnkey solution."}'
                                                          >
                                                            Rely on
                                                            ITsavvy&rsquo;s
                                                            vendor-certified
                                                            experts to design a
                                                            custom kit built
                                                            just for your team,
                                                            creating an
                                                            &ldquo;office in a
                                                            box&rdquo; turnkey
                                                            solution.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"See all assets from start to finish. "}'
                                                          >
                                                            See all assets from
                                                            start to finish.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":12672,"10":2,"11":4,"15":"\"Open Sans\"","16":12}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy helps clients tag assets, record serial numbers, register warranties, and track asset inventory."}'
                                                          >
                                                            ITsavvy helps
                                                            clients tag assets,
                                                            record serial
                                                            numbers, register
                                                            warranties, and
                                                            track asset
                                                            inventory.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-6927"
                    id="menu-item-6927"
                  >
                    <a href="https://www.itsavvy.com/cloud/">Cloud</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7412"
                        id="menu-item-7412"
                      >
                        <div class="vc_row wpb_row vc_row-fluid megamenu-tabs">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div
                                  class="vc_tta-container"
                                  data-vc-action="collapse"
                                >
                                  <div class="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-left">
                                    <div class="vc_tta-tabs-container">
                                      <ul class="vc_tta-tabs-list">
                                        <li
                                          class="vc_tta-tab vc_active"
                                          data-vc-tab=""
                                        >
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612198778214-bd250c1c-82d2"
                                          >
                                            <span class="vc_tta-title-text">
                                              Microsoft 365
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199131181-f0525474-592d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Public
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199158790-1edae25f-7e2d"
                                          >
                                            <span class="vc_tta-title-text">
                                              Hybrid
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1612199194409-76cad718-b4be"
                                          >
                                            <span class="vc_tta-title-text">
                                              Private/Colocation
                                            </span>
                                          </a>
                                        </li>
                                        <li class="vc_tta-tab" data-vc-tab="">
                                          <a
                                            data-vc-container=".vc_tta"
                                            data-vc-tabs=""
                                            href="#1629246346235-88af6c51-8zzzd"
                                          >
                                            <span class="vc_tta-title-text">
                                              Cloud in a Box
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="vc_tta-panels-container">
                                      <div class="vc_tta-panels">
                                        <div
                                          class="vc_tta-panel vc_active hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612198778214-bd250c1c-82d2"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612198778214-bd250c1c-82d2"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Microsoft 365
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/cloud/microsoft-365/">
                                                            Microsoft 365
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/microsoft-365/microsoft-cloud-solution-licensing-and-fulfillment/">
                                                            Microsoft Cloud
                                                            Solution Licensing
                                                            and Fulfillment
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/microsoft-365/security-and-data-protection-services/">
                                                            Security and Data
                                                            Protection Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/microsoft-365/migration-services/">
                                                            Migration Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/microsoft-365/self-service-portal/">
                                                            Self &ndash; Service
                                                            Portal
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/microsoft-365/end-user-enablement-and-help-desk-support/">
                                                            End User Enablement
                                                            and Help Desk
                                                            Support
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/microsoft-365/us-government-and-education-workloads/">
                                                            US Government and
                                                            Education Workloads
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Simple solutions for complex problems. "}'
                                                          >
                                                            Simple solutions for
                                                            complex problems.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy is a trusted CSP for managing and supporting your Microsoft licenses. \r"}'
                                                          >
                                                            ITsavvy is a trusted
                                                            CSP for managing and
                                                            supporting your
                                                            Microsoft licenses.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Don’t give anyone unauthorized access. \r"}'
                                                          >
                                                            Don&rsquo;t give
                                                            anyone unauthorized
                                                            access.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy keeps your Microsoft 365 infrastructure locked down. See how ITsavvy’s expertise in Microsoft and cybersecurity gives you more peace of mind. "}'
                                                          >
                                                            ITsavvy keeps your
                                                            Microsoft 365
                                                            infrastructure
                                                            locked down. See how
                                                            ITsavvy&rsquo;s
                                                            expertise in
                                                            Microsoft and
                                                            cybersecurity gives
                                                            you more peace of
                                                            mind.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Move to the cloud with minimal downtime. \r"}'
                                                          >
                                                            Move to the cloud
                                                            with minimal
                                                            downtime.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy is here to quickly and securely migrate your mailboxes from an on-premise environment and into Microsoft 365."}'
                                                          >
                                                            ITsavvy is here to
                                                            quickly and securely
                                                            migrate your
                                                            mailboxes from an
                                                            on-premise
                                                            environment and into
                                                            Microsoft 365.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Don&apos;t overspend. "}'
                                                          >
                                                            Don&#39;t overspend.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"The ITsavvy self-service portal makes it easy to add, delete, and change Microsoft licenses with just a tap. \r"}'
                                                          >
                                                            The ITsavvy
                                                            self-service portal
                                                            makes it easy to
                                                            add, delete, and
                                                            change Microsoft
                                                            licenses with just a
                                                            tap.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Empower your team."}'
                                                          >
                                                            Empower your team.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Tap into ITsavvy’s Microsoft CSP expertise to get your team back to work with our 24/7 help desk service."}'
                                                          >
                                                            Tap into
                                                            ITsavvy&rsquo;s
                                                            Microsoft CSP
                                                            expertise to get
                                                            your team back to
                                                            work with our 24/7
                                                            help desk service.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Personalized solutions for the public sector. "}'
                                                          >
                                                            Personalized
                                                            solutions for the
                                                            public sector.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Stay secure and compliant with ITsavvy’s certified expertise in the government and education sectors. "}'
                                                          >
                                                            Stay secure and
                                                            compliant with
                                                            ITsavvy&rsquo;s
                                                            certified expertise
                                                            in the government
                                                            and education
                                                            sectors.
                                                          </span>
                                                        </p>

                                                        <h3
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          Learn More
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <a
                                                            href="https://www.itsavvy.com/wp-content/uploads/2021/06/ITsavvy-CSP-Brochure-FINAL-1.pdf"
                                                            rel="noopener"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              alt=""
                                                              class="alignnone size-full wp-image-14569"
                                                              height="132"
                                                              src="https://www.itsavvy.com/wp-content/uploads/2021/06/streamlined-thumb.png"
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              width="103"
                                                            />
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199131181-f0525474-592d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199131181-f0525474-592d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Public
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/cloud/public/">
                                                            Public
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/public/workload-cloud-readiness-assessments/">
                                                            Workload Cloud
                                                            Readiness
                                                            Assessments
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/public/design-and-deployment-services/">
                                                            Design and
                                                            Deployment Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/public/cloud-migration-planning-and-services/">
                                                            Cloud Migration
                                                            Planning and
                                                            Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/public/cloud-optimization/">
                                                            Cloud Optimization
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/public/disaster-recovery-as-a-service/">
                                                            Disaster
                                                            Recovery-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/public/proactive-management-and-monitoring/">
                                                            Proactive Management
                                                            and Monitoring
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Clarify your vision for the cloud. \r"}'
                                                          >
                                                            Clarify your vision
                                                            for the cloud.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s workload cloud readiness assessment helps you plan for a seamless move to the cloud. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            workload cloud
                                                            readiness assessment
                                                            helps you plan for a
                                                            seamless move to the
                                                            cloud.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Get moving in less time. "}'
                                                          >
                                                            Get moving in less
                                                            time.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy moves your organization to the public cloud with our design and development services that are built on your goals and industry best practices. \r"}'
                                                          >
                                                            ITsavvy moves your
                                                            organization to the
                                                            public cloud with
                                                            our design and
                                                            development services
                                                            that are built on
                                                            your goals and
                                                            industry best
                                                            practices.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Legacy systems are too slow. "}'
                                                          >
                                                            Legacy systems are
                                                            too slow.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s cloud migration planning service gets you to the cloud without the disruptions or headaches.\r"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            cloud migration
                                                            planning service
                                                            gets you to the
                                                            cloud without the
                                                            disruptions or
                                                            headaches.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"A cloud that evolves with you. "}'
                                                          >
                                                            A cloud that evolves
                                                            with you.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s cloud optimization service ensures your cloud infrastructure is performing above your expectations and is elastic enough to grow as your needs evolve. "}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            cloud optimization
                                                            service ensures your
                                                            cloud infrastructure
                                                            is performing above
                                                            your expectations
                                                            and is elastic
                                                            enough to grow as
                                                            your needs evolve.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Holistic cloud recovery options. "}'
                                                          >
                                                            Holistic cloud
                                                            recovery options.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s disaster recovery as a service (DRaaS) backs up your computing, storage, and networking configurations securely on the cloud to keep you working, no matter what."}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            disaster recovery as
                                                            a service (DRaaS)
                                                            backs up your
                                                            computing, storage,
                                                            and networking
                                                            configurations
                                                            securely on the
                                                            cloud to keep you
                                                            working, no matter
                                                            what.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Scale fearlessly. "}'
                                                          >
                                                            Scale fearlessly.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Keep your cloud safely performing up to standard. ITsavvy proactively manages and monitors your cloud for issues to give you peace of mind."}'
                                                          >
                                                            Keep your cloud
                                                            safely performing up
                                                            to standard. ITsavvy
                                                            proactively manages
                                                            and monitors your
                                                            cloud for issues to
                                                            give you peace of
                                                            mind.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199158790-1edae25f-7e2d"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199158790-1edae25f-7e2d"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Hybrid
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/cloud/hybrid/">
                                                            Hybrid
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/hybrid/data-center-transformation/">
                                                            Data Center
                                                            Transformation
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/hybrid/assessment-design-and-deployment-services/">
                                                            Assessment, Design
                                                            and Deployment
                                                            Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/hybrid/disaster-recovery-as-a-service/">
                                                            Disaster
                                                            Recovery-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/hybrid/migration-services/">
                                                            Migration Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/hybrid/resource-optimization-services/">
                                                            Resource
                                                            Optimization
                                                            Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/hybrid/managed-hosting-and-colocation/">
                                                            Managed Hosting /
                                                            Colocation
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Reduce cost and complexity. "}'
                                                          >
                                                            Reduce cost and
                                                            complexity.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s data center transformation service helps you make the most of the cloud. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s data
                                                            center
                                                            transformation
                                                            service helps you
                                                            make the most of the
                                                            cloud.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Get to the cloud in less time. "}'
                                                          >
                                                            Get to the cloud in
                                                            less time.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s assessment, design, and deployment services reduce complexity with a customized hybrid cloud migration strategy. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            assessment, design,
                                                            and deployment
                                                            services reduce
                                                            complexity with a
                                                            customized hybrid
                                                            cloud migration
                                                            strategy.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Effortlessly back up essential applications.\r\n"}'
                                                          >
                                                            Effortlessly back up
                                                            essential
                                                            applications.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s disaster recovery as a service (DRaaS) backs up your compute, storage, data, and network configurations securely on the cloud to keep you working, no matter what."}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            disaster recovery as
                                                            a service (DRaaS)
                                                            backs up your
                                                            computing, storage,
                                                            data, and network
                                                            configurations
                                                            securely on the
                                                            cloud to keep you
                                                            working, no matter
                                                            what.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":1389441,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0,"23":1}'
                                                            data-sheets-value='{"1":2,"2":"Customized hybrid solutions—painless migration.\r"}'
                                                          >
                                                            Customized hybrid
                                                            solutions&mdash;painless
                                                            migration.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340865,"3":{"1":0},"10":2,"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Manage your legacy and cloud solutions with ITsavvy. We offer premier hybrid cloud migration services to SMBs, the government, educators, and enterprises. \r"}'
                                                          >
                                                            Manage your legacy
                                                            and cloud solutions
                                                            with ITsavvy. We
                                                            offer premier hybrid
                                                            cloud migration
                                                            services to SMBs,
                                                            the government,
                                                            educators, and
                                                            enterprises.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Guarantee performance over time. "}'
                                                          >
                                                            Guarantee
                                                            performance over
                                                            time.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s hybrid cloud resource optimization service ensures your infrastructure is performing above your expectations and is elastic enough to grow as your needs evolve. "}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            hybrid cloud
                                                            resource
                                                            optimization service
                                                            ensures your
                                                            infrastructure is
                                                            performing above
                                                            your expectations
                                                            and is elastic
                                                            enough to grow as
                                                            your needs evolve.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Enjoy the cloud without the bulky footprint. "}'
                                                          >
                                                            Enjoy the cloud
                                                            without the bulky
                                                            footprint.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Leverage ITsavvy’s sophisticated colocation services to stay compliant and reduce costs."}'
                                                          >
                                                            Leverage
                                                            ITsavvy&rsquo;s
                                                            sophisticated
                                                            colocation services
                                                            to stay compliant
                                                            and reduce costs.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1612199194409-76cad718-b4be"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1612199194409-76cad718-b4be"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Private/Colocation
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/cloud/private-cloud/">
                                                            Private/Colocation
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-links">
                                                      <div class="wpb_wrapper">
                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/private-cloud/data-center-transformation/">
                                                            Data Center
                                                            Transformation
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="https://www.itsavvy.com/cloud/private-cloud/assessment-design-and-deployment-services/">
                                                            Assessment, Design
                                                            and Deployment
                                                            Services
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/colocation-facilities/">
                                                            Colocation
                                                            Facilities
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/disaster-recovery-as-a-service/">
                                                            Disaster
                                                            Recovery-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/backup-as-a-service/">
                                                            Backup-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/desktop-as-a-service/">
                                                            Desktop-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/infrastructure-as-a-service/">
                                                            Infrastructure-as-a-Service
                                                          </a>
                                                        </p>

                                                        <p class="p1">
                                                          <a href="/cloud/private-cloud/data-center-services/">
                                                            Data Center Services
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"End-to-end IT transformation."}'
                                                          >
                                                            End-to-end IT
                                                            transformation.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy has decades of experience improving data center efficiency, ensuring your data center is easy to manage and responds to your business needs. "}'
                                                          >
                                                            ITsavvy has decades
                                                            of experience
                                                            improving data
                                                            center efficiency,
                                                            ensuring your data
                                                            center is easy to
                                                            manage and responds
                                                            to your business
                                                            needs.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Get to the cloud in less time.\r"}'
                                                          >
                                                            Get to the cloud in
                                                            less time.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s assessment, design, and deployment services reduce complexity with a customized cloud migration strategy.\r\n"}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            assessment, design,
                                                            and deployment
                                                            services reduce
                                                            complexity with a
                                                            customized cloud
                                                            migration strategy.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"State-of-the-art private cloud solutions. "}'
                                                          >
                                                            Best of breed
                                                            private cloud
                                                            solutions.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Get the most out of your IT infrastructure with a cloud environment that minimizes expenses with a cloud environment that is scalable, resilient and secure."}'
                                                          >
                                                            Get the most out of
                                                            your IT
                                                            infrastructure with
                                                            a cloud environment
                                                            that minimizes
                                                            expenses with a
                                                            cloud environment
                                                            that is scalable,
                                                            resilient and
                                                            secure.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Back up your essentials. "}'
                                                          >
                                                            Back up your
                                                            essentials.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s disaster recovery as a service (DRaaS) backs up your compute, storage, data, and network configurations securely on the private cloud to keep you working, no matter what."}'
                                                          >
                                                            ITsavvy&rsquo;s
                                                            disaster recovery as
                                                            a service (DRaaS)
                                                            backs up your
                                                            computing, storage,
                                                            data, and network
                                                            configurations
                                                            securely on the
                                                            private cloud to
                                                            keep you working, no
                                                            matter what.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Don&apos;t lose your data. "}'
                                                          >
                                                            Don&#39;t lose your
                                                            data.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s BaaS solution gives you the reliability and peace of mind that your data is always backed up on the cloud, no matter what. "}'
                                                          >
                                                            ITsavvy&rsquo;s BaaS
                                                            solution gives you
                                                            the reliability and
                                                            peace of mind that
                                                            your data is always
                                                            backed up on the
                                                            cloud, no matter
                                                            what.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Collaboration without security headaches. "}'
                                                          >
                                                            Collaboration
                                                            without security
                                                            headaches.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy DaaS enables work-from-anywhere efficiencies with the scalablity and risiliency of the cloud."}'
                                                          >
                                                            ITsavvy DaaS enables
                                                            work-from-anywhere
                                                            efficiencies with
                                                            the scalablity and
                                                            risiliency of the
                                                            cloud.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Scale faster with IaaS."}'
                                                          >
                                                            Scale faster with
                                                            IaaS.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"ITsavvy’s IaaS solution delivers computing, networking, and storage workloads via the private cloud for improved performance. \r"}'
                                                          >
                                                            ITsavvy&rsquo;s IaaS
                                                            solution delivers
                                                            computing,
                                                            networking, and
                                                            storage workloads
                                                            via the private
                                                            cloud for improved
                                                            performance.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <span
                                                            data-sheets-userformat='{"2":15105,"3":{"1":0},"11":4,"12":0,"14":{"1":2,"2":0},"15":"\"Open Sans\", sans-serif","16":12}'
                                                            data-sheets-value='{"1":2,"2":"Offshore your data center needs."}'
                                                          >
                                                            Offshore your data
                                                            center needs.
                                                          </span>
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          <span
                                                            data-sheets-userformat='{"2":340737,"3":{"1":0},"11":4,"12":0,"15":"Open Sans","16":12,"19":0,"21":0}'
                                                            data-sheets-value='{"1":2,"2":"Get business continuity for your data center from the experts. Offshore your data center needs to ITsavvy to streamline your IT operations in the private cloud. \r"}'
                                                          >
                                                            Get business
                                                            continuity for your
                                                            data center from the
                                                            experts. Offshore
                                                            your data center
                                                            needs to ITsavvy to
                                                            streamline your IT
                                                            operations in the
                                                            private cloud.
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  hide">
                                                      <div class="wpb_wrapper">
                                                        <h3>Resources</h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="vc_tta-panel hover"
                                          data-vc-content=".vc_tta-panel-body"
                                          id="1629246346235-88af6c51-8zzzd"
                                        >
                                          <div class="vc_tta-panel-heading">
                                            <h4 class="vc_tta-panel-title">
                                              <a
                                                data-vc-accordion=""
                                                data-vc-container=".vc_tta-container"
                                                href="#1629246346235-88af6c51-8zzzd"
                                              >
                                                <span class="vc_tta-title-text">
                                                  Cloud in a Box
                                                </span>
                                              </a>
                                            </h4>
                                          </div>

                                          <div class="vc_tta-panel-body">
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          <a href="/cloud/itsavvy-cloud-in-a-box/">
                                                            Cloud in a Box
                                                          </a>
                                                        </h3>

                                                        <hr />
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <a href="https://www.itsavvy.com/cloud/itsavvy-cloud-in-a-box/">
                                                            A Turnkey Private
                                                            Cloud Solution
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner">
                                                  <div class="wpb_wrapper">
                                                    <div class="wpb_text_column wpb_content_element  hover-text">
                                                      <div class="wpb_wrapper">
                                                        <h3>
                                                          Digitize your business
                                                          safely and securely.
                                                        </h3>

                                                        <hr />
                                                        <p>
                                                          ITsavvy&rsquo;s Cloud
                                                          in a Box bundle is a
                                                          turnkey private cloud
                                                          solution deployed in
                                                          any one of 200 global
                                                          colocation data
                                                          centers.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="wpb_text_column wpb_content_element  resource hide">
                                                      <div class="wpb_wrapper">
                                                        <p>
                                                          <img
                                                            alt=""
                                                            class="alignnone size-full wp-image-7327"
                                                            height="82"
                                                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/thumb.png"
                                                            width="122"
                                                          />
                                                        </p>

                                                        <p>
                                                          Cloud Solutions
                                                          Brochure
                                                        </p>

                                                        <p>
                                                          <a href="#">
                                                            Download
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-28"
                    id="menu-item-28"
                  >
                    <a href="https://www.itsavvy.com/partners/">Partners</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7418"
                        id="menu-item-7418"
                      >
                        <p>&nbsp;</p>

                        <div class="vc_row wpb_row vc_row-fluid">
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3>The best in the business.</h3>

                                    <hr />
                                  </div>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <p>
                                      ITsavvy&#39;s strong partnerships are the
                                      foundation of our IT solutions. Rely on
                                      ITsavvy to provide you with the right mix
                                      to give you peace of mind.
                                    </p>
                                  </div>
                                </div>

                                <div class="vc_btn3-container vc_btn3-inline">
                                  <a
                                    class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey"
                                    href="https://www.itsavvy.com/partners/"
                                    title="See All Brands"
                                  >
                                    See All Brands
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wpb_column vc_column_container vc_col-sm-8">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/apple/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="264"
                                                sizes="(max-width: 827px) 100vw, 827px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/Apple_Value_Add.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/Apple_Value_Add.png 827w, https://www.itsavvy.com/wp-content/uploads/2021/02/Apple_Value_Add-300x96.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/Apple_Value_Add-768x245.png 768w, https://www.itsavvy.com/wp-content/uploads/2021/02/Apple_Value_Add-190x61.png 190w"
                                                title=""
                                                width="827"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/cisco/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="272"
                                                sizes="(max-width: 368px) 100vw, 368px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2023/05/Cisco-Logo-e1685459970577.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2023/05/Cisco-Logo-e1685459970577.png 368w, https://www.itsavvy.com/wp-content/uploads/2023/05/Cisco-Logo-e1685459970577-300x222.png 300w, https://www.itsavvy.com/wp-content/uploads/2023/05/Cisco-Logo-e1685459970577-190x140.png 190w"
                                                title=""
                                                width="368"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/dell-technologies/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="255"
                                                sizes="(max-width: 879px) 100vw, 879px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Dell-Technologies_Platinum-Partner-Logo-1.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Dell-Technologies_Platinum-Partner-Logo-1.png 879w, https://www.itsavvy.com/wp-content/uploads/2021/03/Dell-Technologies_Platinum-Partner-Logo-1-300x87.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Dell-Technologies_Platinum-Partner-Logo-1-768x223.png 768w, https://www.itsavvy.com/wp-content/uploads/2021/03/Dell-Technologies_Platinum-Partner-Logo-1-190x55.png 190w"
                                                title=""
                                                width="879"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/hewlett-packard-enterprise/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="200"
                                                sizes="(max-width: 192px) 100vw, 192px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/HPE-Logo-nav.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/HPE-Logo-nav.png 192w, https://www.itsavvy.com/wp-content/uploads/2021/02/HPE-Logo-nav-160x167.png 160w"
                                                title=""
                                                width="192"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/hp-inc/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="300"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/My-project-1-69.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/My-project-1-69.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/My-project-1-69-150x150.png 150w, https://www.itsavvy.com/wp-content/uploads/2021/03/My-project-1-69-167x167.png 167w"
                                                title=""
                                                width="300"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/juniper/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="197"
                                                sizes="(max-width: 719px) 100vw, 719px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Juniper-Logo.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Juniper-Logo.png 719w, https://www.itsavvy.com/wp-content/uploads/2021/03/Juniper-Logo-300x82.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Juniper-Logo-190x52.png 190w"
                                                title=""
                                                width="719"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="vc_row wpb_row vc_inner vc_row-fluid partner-nav-row-second">
                                  <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/lenovo/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="252"
                                                sizes="(max-width: 1200px) 100vw, 1200px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1.png 1200w, https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1-300x63.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1-1024x215.png 1024w, https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1-768x161.png 768w, https://www.itsavvy.com/wp-content/uploads/2021/03/Lenovo_logo_2015.svg-1-190x40.png 190w"
                                                title=""
                                                width="1200"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/microsoft-csp/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="157"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/microsoft-csp-logo-color.png"
                                                title=""
                                                width="260"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=Fortinet"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-medium"
                                                decoding="async"
                                                height="150"
                                                sizes="(max-width: 260px) 100vw, 260px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/Fortinet-nav-Logo.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/Fortinet-nav-Logo.png 260w, https://www.itsavvy.com/wp-content/uploads/2021/02/Fortinet-nav-Logo-190x110.png 190w"
                                                title=""
                                                width="260"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="/partners/samsung/"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="159"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Samsung-Logo-1.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Samsung-Logo-1.png 1000w, https://www.itsavvy.com/wp-content/uploads/2021/03/Samsung-Logo-1-300x48.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Samsung-Logo-1-768x122.png 768w, https://www.itsavvy.com/wp-content/uploads/2021/03/Samsung-Logo-1-190x30.png 190w"
                                                title=""
                                                width="1000"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/teamviewer/"
                                              target="_blank"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-medium"
                                                decoding="async"
                                                height="40"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2023/03/download-1-300x40.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2023/03/download-1-300x40.png 300w, https://www.itsavvy.com/wp-content/uploads/2023/03/download-1-190x25.png 190w, https://www.itsavvy.com/wp-content/uploads/2023/03/download-1.png 600w"
                                                title=""
                                                width="300"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="300"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2023/05/My-project-1-72.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2023/05/My-project-1-72.png 300w, https://www.itsavvy.com/wp-content/uploads/2023/05/My-project-1-72-150x150.png 150w, https://www.itsavvy.com/wp-content/uploads/2023/05/My-project-1-72-167x167.png 167w"
                                                title=""
                                                width="300"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=ASUSTeK%20COMPUTER"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-medium"
                                                decoding="async"
                                                height="59"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-300x59.jpg"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-300x59.jpg 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-1024x200.jpg 1024w, https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-768x150.jpg 768w, https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-1536x301.jpg 1536w, https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1-190x37.jpg 190w, https://www.itsavvy.com/wp-content/uploads/2021/03/Asus-Logo-1.jpg 1712w"
                                                title=""
                                                width="300"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/partners/google-chromeos/"
                                              target="_blank"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-medium"
                                                decoding="async"
                                                height="67"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-300x67.jpg"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-300x67.jpg 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-1024x228.jpg 1024w, https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-768x171.jpg 768w, https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-1536x342.jpg 1536w, https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437-190x42.jpg 190w, https://www.itsavvy.com/wp-content/uploads/2021/03/Google-ChromeOS-Logo-scaled-e1686079705437.jpg 1992w"
                                                title=""
                                                width="300"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=Logitech"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="144"
                                                sizes="(max-width: 474px) 100vw, 474px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/03/Logitech-Logo.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/03/Logitech-Logo.png 474w, https://www.itsavvy.com/wp-content/uploads/2021/03/Logitech-Logo-300x91.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/03/Logitech-Logo-190x58.png 190w"
                                                title=""
                                                width="474"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-medium"
                                                decoding="async"
                                                height="300"
                                                sizes="(max-width: 300px) 100vw, 300px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2023/03/My-project-1-73.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2023/03/My-project-1-73.png 300w, https://www.itsavvy.com/wp-content/uploads/2023/03/My-project-1-73-150x150.png 150w, https://www.itsavvy.com/wp-content/uploads/2023/03/My-project-1-73-167x167.png 167w"
                                                title=""
                                                width="300"
                                              />
                                            </div>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-2">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://savvyportal.itsavvy.com/Product/Search/Brand?name=Veeam%20Software"
                                              target="_self"
                                            >
                                              <img
                                                alt=""
                                                class="vc_single_image-img attachment-full"
                                                decoding="async"
                                                height="189"
                                                sizes="(max-width: 1048px) 100vw, 1048px"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280.png"
                                                srcset="https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280.png 1048w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-300x54.png 300w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-1024x185.png 1024w, https://www.itsavvy.com/wp-content/uploads/2021/02/veeam_logo_topaz-500.png.web_.1280.1280-768x139.png 768w"
                                                title="veeam_logo_topaz-500.png.web.1280.1280"
                                                width="1048"
                                              />
                                            </a>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p>&nbsp;</p>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="megamenu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-27"
                    id="menu-item-27"
                  >
                    <a href="https://www.itsavvy.com/industries/">Industries</a>
                    <ul class="sub-menu">
                      <li
                        class="menu-item menu-item-type-gs_sim menu-item-object-gs_sim menu-item-7429"
                        id="menu-item-7429"
                      >
                        <p>&nbsp;</p>

                        <div class="vc_row wpb_row vc_row-fluid">
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3>
                                      Flexible solutions for competitive
                                      industries.
                                    </h3>

                                    <hr />
                                  </div>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <p>
                                      Organizations in the public and private
                                      sectors rely on ITsavvy&rsquo;s experience
                                      for end-to-end IT services and solutions.
                                      Whether you&rsquo;re a university,
                                      municipal organization, global enterprise,
                                      or small business, our solutions flex to
                                      fit your exact needs.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wpb_column vc_column_container vc_col-sm-8">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="/industries/federal-government/"
                                              target="_self"
                                            >
                                              <img
                                                alt="government (1)"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/01/government-1-100x100.png"
                                                title="government (1)"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/federal-government/">
                                                Federal Government
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/industries/state-and-local-government/"
                                              target="_self"
                                            >
                                              <img
                                                alt="small-gov"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/small-gov-100x100.png"
                                                title="small-gov"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/state-and-local-government/">
                                                State/Local Government
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/industries/education/"
                                              target="_self"
                                            >
                                              <img
                                                alt="edu-icon-1-100x100"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/edu-icon-1-100x100-1.png"
                                                title="edu-icon-1-100x100"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/education/">
                                                Education
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/industries/not-for-profits/"
                                              target="_self"
                                            >
                                              <img
                                                alt="charity"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/charity-100x100.png"
                                                title="charity"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/not-for-profits/">
                                                NFP
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/industries/smb/"
                                              target="_self"
                                            >
                                              <img
                                                alt="small-bus-icon"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/small-bus-icon-100x100.png"
                                                title="small-bus-icon"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/smb/">
                                                SMB
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wpb_column vc_column_container vc_col-sm-4">
                                    <div class="vc_column-inner">
                                      <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <figure class="wpb_wrapper vc_figure">
                                            <a
                                              class="vc_single_image-wrapper   vc_box_border_grey"
                                              href="https://www.itsavvy.com/industries/enterprise/"
                                              target="_self"
                                            >
                                              <img
                                                alt="enterprise"
                                                class="vc_single_image-img "
                                                height="100"
                                                loading="lazy"
                                                src="https://www.itsavvy.com/wp-content/uploads/2021/02/enterprise-100x100.png"
                                                title="enterprise"
                                                width="100"
                                              />
                                            </a>
                                          </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <p style={{ textAlign: "center" }}>
                                              <a href="https://www.itsavvy.com/industries/enterprise/">
                                                Enterprise
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p>&nbsp;</p>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11918"
                    id="menu-item-11918"
                  >
                    <a href="https://www.itsavvy.com/why-itsavvy/">
                      Why ITsavvy
                    </a>
                  </li>
                  <li
                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-24 current_page_item menu-item-26"
                    id="menu-item-26"
                  >
                    <a
                      aria-current="page"
                      href="https://www.itsavvy.com/contact-us/"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div class="ecomm--links--mobile">&nbsp;</div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
