import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import {
  CircularProgress,
  TextField,
  Button,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

import useApiWithUrlParams from "./hooks/useApiWithUrlParams";

import RenewalGrid from "./RenewalGrid";
import "./AppStyles.css";
import LoadingScreen from "./LoadingScreen";
import { CoPresentOutlined } from "@mui/icons-material";

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const MainApp = ({ msUsersName, setClientName }) => {
  // const [msUsersName, setmsUsersName] = useState("");

  const [upSideDownID, setupSideDownID] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const queryParams = useQuery();
  const upSideDownIDFromQuery = queryParams.get("upSideDownID");
  const { renewalData, renewalLoading, renewalError } = useApiWithUrlParams(
    "https://pyhtonfunctions.azurewebsites.net/api/getUpsideDownSubs?code=dPhtXWB83tegI8Edxb7RMiaO4X90dn13HeR_I6Bjjd73AzFueBJmmQ==",
    fetchTrigger ? upSideDownID : upSideDownID
  );

  console.log("setClientName is a", typeof setClientName); // Check if it's a function

  console.log("set clientname", setClientName);

  useEffect(() => {
    if (upSideDownIDFromQuery && !upSideDownID) {
      setupSideDownID(upSideDownIDFromQuery);
      setFetchTrigger(true); // Trigger fetch if URL has upSideDownID
    }
  }, [upSideDownIDFromQuery, upSideDownID]);

  useEffect(() => {
    if (renewalData && renewalData.length > 0) {
      setClientName(renewalData[0]["clientName"]);
    }
  }, [renewalData, setClientName]);

  const handleInputChange = (event) => {
    setupSideDownID(event.target.value);
  };

  const handleSubmit = () => {
    setFetchTrigger((prev) => !prev); // Toggle to trigger the API call
  };

  const handleButtonClick = (id) => {
    console.log("Button clicked for ID:", id);
    // Implement logic for button click here
  };

  if (renewalLoading) return <LoadingScreen username={msUsersName} />;
  if (renewalError) return <p>Error: {renewalError.message}</p>;
  console.log("row data", renewalData);

  console.log("nameWeWant from MainApp", msUsersName);

  return (
    <div>
      {/* <AppBar
        position="static"
        style={{ backgroundColor: "var(--primary-color)" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: 1 }}>
            <Typography variant="h6" color="inherit">
              ITsavvy CSP Renewal Tool
            </Typography>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" color="inherit">
              {renewalData ? renewalData[0]["clientName"] : "Enter Renewal ID"}
            </Typography>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Home
            </Link>
          </div>
        </Toolbar>
      </AppBar> */}
      <p></p>

      {renewalData ? (
        <RenewalGrid rowData={renewalData} onButtonClick={handleButtonClick} />
      ) : (
        <>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Fetch Data
          </Button>
          <TextField
            label="Renewal ID"
            variant="outlined"
            value={upSideDownID}
            onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
          />
        </>
      )}
      {renewalLoading && <CircularProgress />}
      {renewalError && <p>Error: {renewalError.message}</p>}
    </div>
  );
};

export default MainApp;
