// hooks/useSetRenewal.js
import axios from "axios";
import { useState } from "react";

function getCustomerIdFromUrl(url) {
  //#endregion const path = new URL(url); // Base URL is needed just for parsing
  console.log("Path:", url);
  const customerId = url.split("/")[2]; // Same as the split method
  console.log("Customer ID:", customerId);
  return customerId;
}

const useSetRenewal = () => {
  const [renewalUpdateLoading, setrenewalUpdateLoading] = useState(false);

  const sendRowDataToApi = async (rowData) => {
    console.log("Row Data:", rowData);

    // Extract necessary fields from rowData

    const { organizationId: orgID, subscriptionId: subID, price } = rowData;

    console.log("Data to send:", {
      subID,
      orgID,
      price,
    });
    setrenewalUpdateLoading(true);
    try {
      const response = await axios.post(
        "https://pyhtonfunctions.azurewebsites.net/api/setCurrentCMPrice?code=jTmllipilyd_l7r7yJZBYKf6ktKJrbY98PqcaQ-3qgKmAzFu_FEnwA==",
        {
          subID,
          orgID,
          price,
        } // send extracted data
      );
      console.log("API Response:", response.data);
      setrenewalUpdateLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error making API call", error);
      throw error;
    }
  };

  return {
    sendRowDataToApi,
    renewalUpdateLoading: renewalUpdateLoading,
  };
};

export default useSetRenewal;
