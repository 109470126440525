import { useState, useEffect } from "react";

const useButtonState = (rowData) => {
  const [disabledButtons, setDisabledButtons] = useState({});

  // Enable or disable a button for a specific row
  const toggleButtonState = (id, state) => {
    setDisabledButtons((prev) => ({ ...prev, [id]: state }));
  };

  // Reset button states when rowData changes
  useEffect(() => {
    const buttonStates = {};
    rowData.forEach((row) => {
      buttonStates[row.id] = false; // Initialize all buttons as enabled
    });
    setDisabledButtons(buttonStates);
  }, [rowData]);

  return { disabledButtons, toggleButtonState };
};

export default useButtonState;
