import React, { useState } from "react";
import MainApp from "../MainApp";

const Main = ({ msUsersName }) => {
  const [clientName, setClientName] = useState(null);

  console.log("setClientName type in mainjsx:", typeof setClientName);
  console.log("clientName in mainjsx:", clientName);

  return (
    <>
      <div class="main template--page-sidebar" id="main">
        <div
          class="post--hero"
          style={{
            backgroundImage:
              "url('https://www.itsavvy.com/wp-content/uploads/2021/02/Tech-refresh-hero_320372134.jpg')",
          }}
        >
          <div class="post--hero-content">
            <div class="container " style={{ paddingBottom: "0px" }}>
              <div class="breadcrumbs yoast--breadcrumbs">
                <p id="breadcrumbs">
                  <span>
                    <span>
                      <a href="https://www.itsavvy.com/">Home</a>
                    </span>{" "}
                    /{" "}
                    <span aria-current="page" class="breadcrumb_last">
                      ITsavvy CSP Team
                    </span>
                  </span>
                </p>
              </div>

              <div class="post--hero-titles">
                <h1 class="post--title" style={{ textAlign: "center" }}>
                  {clientName ? (
                    <span>
                      CSP Renewal for{" "}
                      <span style={{ color: "#FF6303" }}>{clientName}</span>
                    </span>
                  ) : (
                    "ITsavvy CSP Price Fix Tool"
                  )}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container grid-container page--sidebar-content"
          style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div class="row">
            <div class="grid-item full">
              <MainApp
                msUsersName={msUsersName}
                setClientName={setClientName}
                clientName={clientName}
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row textwidget widget">
        <div class="column">
          <p>
            <strong>Phone</strong>
          </p>
          <p>1.855.ITsavvy</p>
        </div>

        <div class="column">
          <p>
            <strong>Email</strong>
          </p>
          <p>
            <a href="mailto:savvycloud@itsavvy.com">savvycloud@itsavvy.com</a>
          </p>
        </div>

        <div class="column">
          <p>
            <strong>IT Products Line Card</strong>
          </p>
          <a
            download=""
            href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy_Line-Card_20210212.pdf"
          >
            <strong>Download</strong>
          </a>
        </div>

        <div class="column">
          <p>
            <strong>Corporate Brochure</strong>
          </p>
          <p>
            <a
              download=""
              href="https://www.itsavvy.com/wp-content/uploads/2021/02/ITsavvy-Brochure-V6-WEBSITE.pdf"
            >
              <strong>Download</strong>
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Main;
