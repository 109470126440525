import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

const LoadingScreen = ({ username }) => {
  const [phrase, setPhrase] = useState("");
  const facts = [
    "Did you know? Setting auto-renewal can reduce manual renewal management time by 30%.",
    "Fun fact: The first documented concept of auto-renewal was introduced in 1987.",
    "Pro tip: Regularly update your renewal settings to match client needs and improve satisfaction.",
    "Reminder: Check your renewal reports every Monday to stay ahead!",
  ];

  // Encouraging phrases
  const phrases = [
    "Hang tight, your data is on its way!",
    "Thanks for your patience!",
    "Almost there, we are loading your content!",
  ];

  // Determine the greeting based on the time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  };

  useEffect(() => {
    // Change the phrase every 4 seconds
    const phraseInterval = setInterval(() => {
      const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];
      setPhrase(randomPhrase);
    }, 4000);

    return () => clearInterval(phraseInterval);
  }, [phrases]);

  // Random fact for each load
  const randomFact = facts[Math.floor(Math.random() * facts.length)];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="top"
      flexDirection="column"
      height="auto"
    >
      <CircularProgress color="secondary" />
      <Typography variant="h5" marginTop={2}>
        {getGreeting()} {username}! Fetching your data...
      </Typography>
      <Typography variant="subtitle1" marginTop={2}>
        {phrase}
      </Typography>
      <Typography variant="body1" marginTop={2}>
        {randomFact}
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
