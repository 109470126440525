import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { msalConfig } from "./authConfig";
import SignInButton from "./signIn";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Main from "./Components/Main";
import AppProvider, { useAppContext } from "./providers/AppContext";
const pca = new PublicClientApplication(msalConfig);

const AuthenticatedApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState("");
  const [msUsersName, setmsUsersName] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      const accounts = pca.getAllAccounts();
      if (accounts.length > 0) {
        // Adjust scopes as necessary for your application
        const customApiTokenRequest = {
          scopes: ["api://1433182e-75cb-4756-a8b4-8f75ea731314/api-scope"],
          account: accounts[0], // Assuming the user is signed in
        };
        setmsUsersName(accounts[0].name);
        pca
          .acquireTokenSilent(customApiTokenRequest)
          .then((response) => {
            // Use the token to call your custom API
            setToken(response.accessToken);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [isAuthenticated]);

  // Function to make API calls and process them
  if (!isAuthenticated) {
    return <SignInButton />;
  }

  return (
    <>
      <div class="wrapper">
        <Main msUsersName={msUsersName ?? ""} />
      </div>
    </>
  );
};

function App() {
  return (
    <Router>
      <MsalProvider instance={pca}>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<AuthenticatedApp />} />
          </Routes>
          <Footer />
        </div>
      </MsalProvider>
    </Router>
  );
}

export default App;
