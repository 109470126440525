import React from 'react'

const Footer = () => {
    return (
        <>
            <footer>
                <div class="container">
                    <div class="main--footer">
                        <div class="footer--logo"><a href="https://www.itsavvy.com/itsavvyracing/"><img alt=""
                            src="https://www.itsavvy.com/wp-content/uploads/2022/12/itsavvy-footer-logo-300.png" /> </a><a
                                href="/official-it-solutions-provider-of-chicago-fire-fc-delivers-critical-infrastructure-upgrade/">
                                <img alt=""
                                    src="https://www.itsavvy.com/wp-content/uploads/2021/03/itsavvy-fire-footer-final-final.svg" />
                            </a></div>

                        <div class="footer--menus">
                            <div class="footer--menu-one footer--menu">
                                <div class="menu-footer-1-container">
                                    <ul class="menu" id="menu-footer-1">
                                        <li class="my-account-link menu-item menu-item-type-custom menu-item-object-custom menu-item-104"
                                            id="menu-item-104"><a href="https://savvyportal.itsavvy.com/">My Account</a></li>
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-105"
                                            id="menu-item-105"><a href="https://www.itsavvy.com/new-account-request/">New Account
                                                Request</a></li>
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-106"
                                            id="menu-item-106"><a href="https://savvyportal.itsavvy.com/">Login/Forgot Password</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14258"
                                            id="menu-item-14258"><a href="https://www.itsavvy.com/tech-support/">Tech Support</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="footer--menu-two footer--menu">
                                <div class="menu-footer-2-container">
                                    <ul class="menu" id="menu-footer-2">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-139"
                                            id="menu-item-139"><a href="https://www.itsavvy.com/about-us/">About Us</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-138"
                                            id="menu-item-138"><a href="https://www.itsavvy.com/about-us/locations/">Locations</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-137"
                                            id="menu-item-137"><a href="https://www.itsavvy.com/about-us/leadership/">Leadership</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-135"
                                            id="menu-item-135"><a href="https://www.itsavvy.com/about-us/careers/">Careers</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-136"
                                            id="menu-item-136"><a href="https://www.itsavvy.com/about-us/resource-center/">Resource
                                                Center</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="footer--menu-three footer--menu">
                                <div class="menu-footer-3-container">
                                    <ul class="menu" id="menu-footer-3">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-144"
                                            id="menu-item-144"><a href="https://www.itsavvy.com/why-itsavvy/">Why ITsavvy</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142"
                                            id="menu-item-142"><a
                                                href="https://www.itsavvy.com/why-itsavvy/success-stories/">Success Stories</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143"
                                            id="menu-item-143"><a
                                                href="https://www.itsavvy.com/why-itsavvy/mission-vision-promise/">Mission, Vision,
                                                Promise</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-141"
                                            id="menu-item-141"><a
                                                href="https://www.itsavvy.com/why-itsavvy/our-guiding-principles/">Our Guiding
                                                Principles</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-145"
                                            id="menu-item-145"><a href="https://www.itsavvy.com/why-itsavvy/awards/">Awards</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-140"
                                            id="menu-item-140"><a href="https://www.itsavvy.com/trending/">Trending</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="footer--social">
                            <ul>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                            </ul>
                        </div>
                    </div>

                    <div class="bottom--bar">
                        <div class="bbb-logo"><a
                            href="https://www.bbb.org/us/il/addison/profile/information-technology-services/itsavvy-llc-0654-88413234/#sealclick"
                            target="_blank"><img alt=""
                                src="https://www.itsavvy.com/wp-content/uploads/2020/07/bbb.png?ver=1.0" /> </a></div>

                        <div class="nmsdc_logo"><img alt=""
                            src="https://www.itsavvy.com/wp-content/uploads/2023/04/NMSCE-MBE-CERTIFIED.png" /></div>

                        <div class="bbb-logo"><img alt=""
                            src="https://www.itsavvy.com/wp-content/uploads/2021/02/MSP-Alliance-logo.png" /></div>

                        <div class="iso_900_logo"><img alt=""
                            src="https://www.itsavvy.com/wp-content/uploads/2023/04/MS_Website_Image-short.png" /></div>

                        <div class="bottom--bar-menu">
                            <ul>
                                <li><a href="/privacy-policy/">Privacy Policy</a></li>
                                <li><a href="/return-policy/">Return Policy</a></li>
                                <li><a href="/termsandconditions/">Terms &amp; Conditions</a></li>
                                <li><a href="/ccpa-addendum/">CCPA</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="bottom--bar copyright">
                        <div class="minority-business">ITsavvy is a Certified Minority Business Enterprise</div>

                        <div class="copyright">Copyright &copy; 2004 - 2024 ITsavvy. All rights reserved. &reg;Registered Trademarks
                            of ITsavvy LLC. All other company and product names may be trademarks of respective companies with which
                            they are associated.</div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer